<template>
  <div class="o-search-panel" ref="scrollable-panel">
    <div
      v-if="noResultsMessage"
      class="no-results"
    >
      {{ $t(noResultsMessage) }}
    </div>
    <div v-else class="container">
      <div class="categories">
        <strong class="categories__title">
          {{ $t("Categories") }}
        </strong>
        <SfList v-if="visibleProducts.length && categories.length > 1" class="categories__listing">
          <SfListItem
            v-for="category in categories"
            :key="category.category_id"
          >
            <SfMenuItem
              :class="{'selected': isCategorySelected(category)}"
              :label="category.name"
              icon=""
              @click="toggleCategory(category)"
            />
          </SfListItem>
        </SfList>
      </div>
      <div class="products">
        <strong class="products__title">
          {{ $t("Product suggestions") }}
        </strong>
        <div class="products__listing">
          <OProductCard
            class="products__product-card"
            v-for="(product, key) in visibleProducts"
            :key="`${key}-${product.id}`"
            :title="product.title"
            :image="product.image"
            :hover-image="product.hover_image"
            :regular-price="product.price.regular"
            :special-price="product.price.special"
            :final-price="product.price.final"
            :max-rating="product.rating.max"
            :score-rating="product.rating.score"
            :link="product.link"
            :sku="product.sku"
            :parent-sku="product.parentSku"
            @click:wishlist="toggleWishlist(productList[key])"
            :is-on-wishlist="isOnWishlist(productList[key])"
            :type-id="product.typeId"
            :gtm-custom-dimensions="product.gtmCustomDimensions"
            :gtm-item-list="gtmItemList"
          />
        </div>
        <SfButton
          v-if="OnlineOnly && readMore && visibleProducts.length >= pageSize"
          class="sf-button--full-width load-more"
          type="button"
          @click="$emit('see-more')"
        >
          {{ $t("Load more") }}
        </SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config';
import VueOfflineMixin from 'vue-offline/mixin';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapActions } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers'
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfList from '@storefront-ui/vue/src/components/organisms/SfList/SfList.vue';
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue';
import { gtmItemLists } from 'src/modules/google-tag-manager/types/gtmEvents.ts';

import { prepareListingProduct } from '~/theme/helpers';
import OProductCard from '~/theme/components/organisms/o-product-card';
import { RemoveFromWishlist } from '@vue-storefront/core/modules/wishlist/components/RemoveFromWishlist';
import { AddToWishlist } from '@vue-storefront/core/modules/wishlist/components/AddToWishlist';
export default {
  name: 'OSearchPanel',
  components: {
    SfButton,
    SfList,
    SfMenuItem,
    OProductCard
  },
  mixins: [VueOfflineMixin, RemoveFromWishlist, AddToWishlist],
  props: {
    search: {
      type: String,
      required: true,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 16
    },
    products: {
      type: Array,
      required: true,
      default: () => ([])
    },
    readMore: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      selectedCategoryIds: [],
      gtmItemList: gtmItemLists.SearchResults
    };
  },
  watch: {
    visibleProducts: {
      handler (val) {
        if (isServer) return
        const listOfSkus = val.map(product => product.parentSku)
        this.getReviewsSummary({ skus: listOfSkus })
      },
      immediate: true,
      deep: true
    },
    categories () {
      this.selectedCategoryIds = [];
    }
  },
  computed: {
    productList () {
      const productList = this.selectedCategoryIds.length
        ? this.products.filter(product => {
          const ids = product.category_ids || []
          return ids.some(categoryId => this.selectedCategoryIds.includes(categoryId))
        })
        : this.products;

      return productList
    },
    visibleProducts () {
      return this.productList.map(prepareListingProduct);
    },
    categories () {
      const distinctCategories = this.products
        .filter(product => product.category)
        .map(product => product.category)
        .flat()
        .reduce((result, category) => {
          result[category.category_id] = category;
          return result;
        }, {});

      const result = Object.values(distinctCategories)

      return result.filter((category) => !this.excludedCategoryIds.includes(category.category_id));
    },
    noResultsMessage () {
      return this.search.length < 3
        ? 'Searched term should consist of at least 3 characters.'
        : !this.visibleProducts.length
          ? 'No results were found.'
          : '';
    },
    excludedCategoryIds () {
      return config.excludedFilterCategoryIds || []
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      getReviewsSummary: 'getSummary'
    }),
    isCategorySelected (category) {
      return this.selectedCategoryIds.includes(category.category_id);
    },
    toggleCategory (category) {
      if (this.isCategorySelected(category)) {
        this.selectedCategoryIds = this.selectedCategoryIds.filter(categoryId => categoryId !== category.category_id);
      } else {
        this.selectedCategoryIds.push(category.category_id);
      }
    },
    closeSidebar () {
      this.$store.dispatch('ui/setSearchpanel', false)
    },
    toggleWishlist (product) {
      this.isOnWishlist(product) ? this.removeProductFromWhishList(product) : this.addProductToWhishlist(product);
    },
    addProductToWhishlist (product) {
      this.addToWishlist(product);
    },
    removeProductFromWhishList (product) {
      this.removeFromWishlist(product);
    },
    isOnWishlist (product) {
      return this.$store.getters['wishlist/isOnWishlist'](product)
    }
  },
  mounted () {
    if (!isServer) {
      disableBodyScroll(this.$refs['scrollable-panel'])
    }
  },
  destroyed () {
    clearAllBodyScrollLocks()
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-search-panel {
  background: var(--c-white);
  width: calc(100% - var(--spacer-sm));
  max-height: calc(100vh - var(--header-full-height) - 3.7rem);
  top: auto;
  left: var(--spacer-xs);
  position: fixed;
  overflow: auto;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  z-index: var(--search-z-index);

  @include for-desktop {
    left: 0;
    width: 100%;
    max-height: calc(100vh - 16rem);
    top: var(--header-full-height);
  }

  .container {
    display: flex;
    max-width: var(--container-width);
    margin: auto;
    padding: 0 var(--spacer-base);
    @include for-desktop {
      border-top: 1px solid var(--c-light);
    }
    @include for-mobile {
      flex-direction: column;
      height: calc(100vh - 5rem);
    }
  }

  .categories {
    order: 2;
    padding-bottom: 1rem;
    @include for-desktop {
      order: 1;
      flex: 0 0 20%;
      padding-right: 3rem;
      border-right: 1px solid var(--c-light);
    }

    &__title {
      padding: 0;
      font-size: var(--font-size--lg);
      font-weight: 500;
      line-height: 3;
    }
    &__listing {
      @include for-desktop {
        margin-top: 2rem;
      }

      .sf-list__item {
        padding: 0.3rem 0;
      }
      .sf-menu-item.selected {
        --menu-item-font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  .products {
    order: 1;
    width: 100%;
    @include for-desktop {
      order: 2;
      padding-left: 3rem;
    }
    &__title {
      padding: 0;
      font-size: var(--font-size--lg);
      font-weight: 500;
      line-height: 3;
    }
    &__listing {
      display: flex;
      flex: 0 1 auto;
      flex-wrap: wrap;
      margin: -1rem -1rem 0;
    }
    &__product-card {
      padding: var(--spacer-sm) var(--spacer-xs) var(--spacer-sm);
      min-width: 50%;
      max-width: 25%;
      @include for-desktop {
        min-width: 25%;
      }
    }
  }

  .no-results {
    line-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--font-size--sm);
    padding: var(--spacer-sm);
    height: 5rem;
    @include for-desktop {
      font-size: var(--font-size--base);
    }
  }

  .load-more {
    margin: var(--spacer-xl) 0;
  }
}
</style>
